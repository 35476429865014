<template>
  <div class="regionalSettings">客户消费统计 </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  created() { },
  methods: {},
  mounted() { },
}
</script>
<style lang="less" scoped>
</style>